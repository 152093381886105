<template>
    <div class="lucky">
      <div class="lucky-top d-flex justify-content-center">
        <img src="@/assets/imgV2/lucky/page_title.png" alt="">
      </div>
      <div class="lucky-box">
        <div class="lucky-box-con">
          <div class="d-none d-xl-block">
            <img width="230px" :src="detail.cover" alt="">
          </div>
          <div class="lucky-box-con-center position-relative">
            <div class="lucky-box-con-center-wai"></div>
            <div :class="`${isShow?'lucky-box-con-center-shandian':''}`"></div>
            <div class="lucky-box-con-center-tow"></div>

            <div class="lucky-box-con-center-therr"></div>
            <div id="progress" class=" position-absolute progress_lucky">
              <el-progress :stroke-width="4" stroke-linecap="butt" color="#cc33ff"
                           type="circle" :percentage="successRate"></el-progress>
            </div>
            <div class="position-relative">
              <img src="@/assets/imgV2/lucky/zhizhen.png" alt="" :style="`${isShow?`transform: rotate(${successRateTow}deg);transition: all 6s;`:''}`">
            </div>
          </div>
          <!-- 左侧 -->
          <div class="d-none d-xl-block">
            <img width="230px" :src="require('@/assets/img/random_goods.png')" alt="">
          </div>
        </div>



        <div class="col-lg-6 col-md-10 col-12 mx-auto my-4" >
          <el-slider v-if="!isShow" class="content-top-center-slider" :min="5" :max="75" :marks="marks"  v-model="successRate "></el-slider>
        </div>
         <div class="d-flex justify-content-center w-100">
              <money/>
                {{ money }}
              </div>
        <div class="d-flex w-100 justify-content-center mt-5">
          <img @click="openBox" src="@/assets/imgV2/lucky/upgrade-btn.png" alt="">
        </div>
      </div>
      <history :id="id" ref="history"></history>

        <div v-if="openShow">
            <div class="position-fixed z-index-100" style="width: 100vw;height: 100vh;left: 0;top: 0;background-color: rgba(0,0,0,.2)">
              <div  style="top: 0;left: 0;" class="  py-5 px-4 d-flex justify-content-center align-items-center" >
                <div>
                  <div class="awardListPop-box d-flex justify-content-center pt-5 flex-wrap ">
                    <div style="height:fit-content"
                         :class="'pic-bg-border-'+ Math.floor((Math.random()*4)+1)"
                         class="text-center award-box ">
                      <img width="80%" :src="drawDetail.cover" alt="">
                      <div class="my-1 text-overflow-1 ">{{drawDetail.name}}</div>
                      <div class="d-flex justify-content-between" style="font-size: 12px">
                        <div class="border-0 text-center w-50 p-2">
                          <money class="money-phone"/>{{drawDetail.bean}}
                        </div>
                        <div class="border-0 text-center w-50 p-2
                                cursor-pointer" >
                          {{drawDetail.dura_alias}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex px-md-5 justify-content-center mt-2">
                    <div @click="openShowOff" >
                      <bottom-bg >
                        <div style="color: #FFFFFF;font-size: 12px">
                          收下饰品
                        </div>
                      </bottom-bg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
    import history from "./history.vue"
    import $api from '@/api/lucky/index'
    import bottomBg from "@/components/bottomBg";
    export default {
        name: "active",
        components:{
          bottomBg,
          history
        },
        data() {
            return {
                successRate:5,
                id:'',
                detail:{},
                drawDetail:{},
                openShow:false,
                isShow:false,
                zuzhidianji:false,
                multiDrawData:[
                    {
                        drawData: [],
                        drawDataSuccess: {}

                    }
                ],
                resData: {
                    WH: {
                        width: "220",
                        height: "150",

                    },
                    IdBox: ["wi", "id_box",'fontSize'],
                    classBox: ["fontSize", ""],
                    classImg: ["imgwh"],
                },
                timeplay:false,
              marks:{
                5:'5',
                10:'10',
                20:'20',
                30:'30',
                40:'40',
                50:'50',
                60:'60',
                70:'70',
                75:'75',
              },
              successRateTow:1,
            }
        },
        computed:{
            money(){
                return ((this.successRate/100)*(this.detail.bean*1)).toFixed(2)
            },
            successRateNum(){
                return this.successRate
            }
        },
        created() {
          this.timeplay = false
            this.id = this.$route.params.id
            this.init()
            },
        mounted() {
        this.init()
            },
        beforeDestroy(){
          this.timeplay = true
        },
        destroyed () {
        this.timeplay = true
      },
        methods:{
            async init(){
                await this.getluckydetail()
            },
            getComplete(){
              if(!this.timeplay){
                document.getElementById('succeed').play()
              }

              this.$refs.helloworld.play.restart(true);
              this.$refs.helloworld.play.pause()
              this.zuzhidianji = false
              this.openShow = true
            },
            async getluckydetail(){
                try {
                    let {data,code} = await $api.getluckydetail(this.id)
                    if(code == 500)return
                    this.detail = data
                    let drawData = {
                        src:this.detail.cover,
                        name:this.detail.name,
                    }
                    let arr = []
                    for (let i = 0;i<10;i++){
                        arr.push({
                            src:require('@/assets/img/random_goods.png'),
                            name:'随机饰品',
                        })
                        arr.unshift(drawData)
                    }
                    this.multiDrawData[0].drawData.push(...arr)
                    console.log(arr)
                }catch (e) {
                    console.log(e)
                    return
                }
            },
            getRandomNumber(min,max){
              return Math.floor(Math.random() * (max - min + 1) + min);
            },
            async openBox(){
                try {
                    let {data,code} = await $api.getluckyOpen({id:this.id,percent:(this.successRate/100).toFixed(2)})
                    if(code == 500)return
          document.getElementById('luckey').play()
                  this.isShow = true
                  if(this.id == data.skin_id){
                    this.successRateTow = 360*3 + 360*(this.successRate/100) - Math.floor(Math.random()*(360*(this.successRate/100)))
                  }else {
                    this.successRateTow = 360*3 + 360*(this.successRate/100) + this.getRandomNumber(360*(this.successRate/100)+1,360) - 360*(this.successRate/100)
                  }
                  setTimeout(()=>{
                    this.openShow = true

                    this.drawDetail = data
                    this.$store.dispatch('userInfo')

                  },6000)
                }catch (e) {
                    console.log(e)
                    return
                }
            },
            openShowOff(){
                this.openShow = false
                this.isShow = false
              this.$refs['history'].getHistory()
            },
        }
    }
</script>

<style lang="scss" scoped>
  .lucky-box-con-center-shandian{
    position: absolute;
    width: 90%;
    height: 90%;
    animation: animationShanDian .5s linear infinite;
    background-size: 90% 90%;
    background-image: url("../../assets/imgV2/lucky/shengji_1.png");
  }
  .lucky-top{
    width: 80%;
    margin: 0 auto;
  }
  .lucky-box{
    width: 80%;
    margin: 20px auto;
    padding: 50px;
    background-size: 100% 100%;
    background-image: url("../../assets/imgV2/lucky/lucky-bg.png");
    @media (max-width: 1000px) {
      padding: 50px 10px;
      width: 96%;
      background-size: cover;
      background-position: center;
    }
    &-con{
      height: 330px;
      background-size: 100% 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-image: url("../../assets/imgV2/lucky/lucky-center-bg.png");
      @media (max-width: 1000px) {
        background-image:none;
      }
      &-center{
        width: 350px;
        height: 350px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

      }
      &-center-wai{
        width: 350px;
        height: 350px;
        position: absolute;
        background-size: 100% 100%;
        animation: trun 40s linear infinite;
        background-image: url("../../assets/imgV2/lucky/percentage_turntable.png");
      }
      &-center-tow{
        width: 170px;
        height: 170px;
        position: absolute;
        background-size: 100% 100%;
        background-image: url("../../assets/imgV2/lucky/percentage_circle.png");
      }
      &-center-therr{
        width: 240px;
        height: 240px;
        position: absolute;
        background-size: 100% 100%;
        background-image: url("../../assets/imgV2/lucky/hxd_percentage_scale_2.png");
      }
    }
  }


  @keyframes trun {
    to {
      transform: rotate(1turn)
    }
  }


  @keyframes animationShanDian  {
    10%{
      transform:rotate(10deg) scale(0);
    }
    10%{
      transform:rotate(45deg) scale(.5);
    }
    20%{
      transform:rotate(90deg) scale(0);
    }
    30%{
      transform:rotate(125deg) scale(.6);
    }
    40%{
      transform:rotate(160deg) scale(0);
    }
    50%{
      transform:rotate(185deg) scale(.8);
    }
    60%{
      transform:rotate(220deg) scale(0);
    }
    70%{
      transform:rotate(255deg) scale(.4);
    }
    80%{
      transform:rotate(290deg) scale(0);
    }
    0%{
      transform:rotate(300deg) scale(.3);
    }
    90%{
      transform:rotate(330deg) scale(0);
    }
    100%{
      transform:rotate(360deg) scale(1);
    }

  }

















</style>
